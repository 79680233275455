<!-- 首页页面 -->
<template>
    <div class="home-container">
        <!-- 头部 -->
        <the-header></the-header>
        <!-- 告示区 -->
        <div ref="noticeDom" class="home-notice">
            <!-- <div class="home-notice-left">
                <h1>盛易通云平台 V 2.0</h1>
                <p>内容更新中，敬请期待！</p>
                <p>聚焦产业的供应链金融科技平台，</p>
                <p>让供应链更高效 金融更普惠</p>
                <div class="home-notice-left-btns">
                    <div>了解详情</div>
                    <div>免费注册</div>
                </div>
            </div>
            <div class="home-notice-right">
                <img :src="require('@imgs/home/noticepc.png')" alt="" srcset="" width="674px" height="542px">
            </div> -->
            <el-carousel trigger="click" height="800px" class="home-notice-banner-wrap" :style="{width: bannerWidth+'px'}">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="home-notice-banner">
                        <img :src="item.src" alt="" srcset="" @click="goWebsite">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 产品中心 -->
        <div ref="productDom" class="home-wrap" style="margin-top: 739px; background-color: #f3f9ff; height: 1200px;">
            <h1 class="home-title">
                产品中心
            </h1>
            <p class="home-subtitle">双驱动·大平台，助力产业科技发展，丰富数字金融场景</p>
            <ul class="home-product">
                <li v-for="item in productIntroList" :key="item.title" class="home-product-card">
                    <img :src="item.icon" alt="" srcset="">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.intro }}</p>
                </li>
            </ul>
        </div>
        <!-- 数字化解决方案 -->
        <div ref="digitizingDom" class="home-wrap home-digitizing-bg" style="height: 859px;">
            <h1 class="home-title">
                数字化解决方案
            </h1>
            <p class="home-subtitle">运用数字科技服务金融和实体产业，助力三大行业降低成本、提升效率、模式升级</p>
            <!-- <p class="home-subtitle">运用数字科技服务金融和实体产业，助长三大行业降低成本，提速效率，模式升级</p> -->
            <div class="home-digitizing">
                <ul class="home-digitizing-ul">
                    <li v-for="(item, index) in digitizingIntroList" :key="item.img" class="home-digitizing-li" :class="{ 'active': digitizingIndex === index }" @mouseover="digitizingIndex = index">
                        {{ item.title }}
                    </li>
                </ul>
                <div class="home-digitizing-content">
                    <p style="width: 500px;" v-html="digitizingIntroList[digitizingIndex].intro"></p>
                    <!-- <img :src="require('@imgs/home/digitizing.png')"> -->
                </div>
            </div>
        </div>
        <!-- 行业动态 -->
        <div class="home-wrap home-industry-bg" style="height: 956px;">
            <h1 class="home-title" style="color: #fff;">
                行业动态
            </h1>
            <p class="home-subtitle" style="color: #fff;">行业最新前沿技术分享</p>
            <ul class="home-industry">
                <li v-for="item in industryNewsList" :key="item.title" class="home-industry-li">
                    <div class="home-industry-img">
                        <img :src="item.img" alt="" srcset="" class="zoom-in-and-out">
                    </div>
                    <p class="home-industry-time">{{ item.time }}</p>
                    <a :href="item.href" class="home-industry-text" target="_blank">
                        <h2 class="home-industry-title ellipsis">
                            {{ item.title }}
                        </h2>
                        <!-- <p class="home-industry-intro">{{ item.intro }}</p> -->
                    </a>
                </li>
            </ul>
        </div>
        <!-- 尾部 -->
        <the-footer></the-footer>
    </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
export default ({
    name: 'Index',
    components: {
        TheHeader,
        TheFooter
    },
    data() {
        return {
            /* 告示区 */
            bannerList: [
                { src: require('@imgs/home/banner/1.png') },
                { src: require('@imgs/home/banner/2.png') },
                { src: require('@imgs/home/banner/3.png') }
            ],
            bannerWidth: 1920,
            /* 产品中心的介绍 */
            productIntroList: [
                { title: '盛易通云平台', icon: require('@imgs/home/product/1.png'), intro: '赋能中小微企业，助力中小微企业融资，并通过强大的大数据风控技术向金融机构赋能。这一平台整体提升了行业的供应链管理能力，让供应链更高效，金融更普惠。' },
                { title: '智能中登服务', icon: require('@imgs/home/product/2.png'), intro: '提供快速高效低成本低风险的应收账款融资查重和登记服务，包括中登登记、中登查重、贷后监控三大功能模块。' },
                { title: '发票验真', icon: require('@imgs/home/product/3.png'), intro: '支持9类增值税发票的信息核验，包括专票、电子专票、普票、电子普票、卷票、通行费增值税电子普通发票、货物运输业增值税专用发票、机动车销售发票、二手车销售发票。' },
                { title: '智能文本分析与应用', icon: require('@imgs/home/product/4.png'), intro: '提供通用文字识别、卡证票据结构化识别、表格文档批量结构化识别、财务报表解析等能力，满足业务场景提供高度定制化的识别需求。' },
                { title: '发票易', icon: require('@imgs/home/product/5.png'), intro: '利用互联网技术，致力于打造发票管理为核心的数据归集、进项管理、销项管理、风险预警等场景应用的一体化解决方案。' },
                { title: '电子签章', icon: require('@imgs/home/product/6.png'), intro: '通过API接口、网页、APP等方式，实现随时随地完成电子合同的实时签署，确保其安全、合规、合法、不可篡改，助力用户提高业务效率、降低业务成本。' }
            ],
            /* 数字解决方案介绍 */
            digitizingIntroList: [
                { title: '基建工程', intro: '把科技真正融入智慧生产和整个行业智慧化推动的过程中，相信智慧化平台是一个有利工具”，通过物联网加上数字赋能为建筑工地的施工进行优化，利用科技的手段，运用到项目管理现场并得以提升施工现场的安全质量、劳务机械各个方面的综合管理能力。' },
                { title: '医药医疗', intro: '医院SPD服务结合了供应链管理SaaS软件、 物联网设备以及大数据运营，强化医院医用耗材管理部门的全程监管，协调外部与内部需求为主导，对全院医用耗材在院内的供应、加工、配送等物流的集中管理模式，全面优化医院的全链信息化管理。' },
                { title: '能源化工', intro: '敬请期待' },
                { title: '其他行业', intro: '敬请期待' }
            ],
            digitizingIndex: 0,
            /* 行业动态 */
            industryNewsList: [
                { img: require('@imgs/home/industryNews/1.png'), time: '2022-01-13', title: '科技价值持续释放 盛业荣获第六届金港股 “最佳SaaS公司”奖', intro: '敬请期待', href: 'https://www.syholdings.com/news/detail-7-71-502-1.html' },
                { img: require('@imgs/home/industryNews/2.png'), time: '2022-01-12', title: '盛业受邀出席第三届国际保理和供应链金融大会，荣获2021年度行业贡献单位奖及社会责任突出贡献单位奖', intro: '敬请期待', href: 'https://www.syholdings.com/news/detail-7-71-501-1.html' },
                { img: require('@imgs/home/industryNews/3.png'), time: '2022-01-06', title: '盛易通云平台获得国家对非银行机构安全系统的最高级别认证 用户信息安全保障再升级', intro: '敬请期待', href: 'https://www.syholdings.com/news/detail-7-71-499-1.html' }
            ]
        }
    },
    mounted() {
        this.bannerWidthAdap()
    },
    beforeDestroy() {
        window.onresize = null
    },
    methods: {
        // 控制轮播图宽度自适应
        bannerWidthAdap() {
            const that = this
            window.onresize = () => {
                return (() => {
                    const screenWidth = document.body.clientWidth
                    if (1920 > screenWidth) {
                        that.bannerWidth = screenWidth
                    } else {
                        that.bannerWidth = 1920
                    }
                })()
            }
        },
        goWebsite() {
            window.open('https://client.sytechnology.com/index')
        }
    }
})
</script>

<style lang="scss" scoped>
    .home-container {
        text-align: center;
        position: relative;
    }
    .home-notice {
        // height: 800px;
        width: 100%;
        position: absolute;
        top: 0;
        text-align: center;
        background-color: #edeeef;
        white-space: nowrap;
        .home-notice-left {
            display: inline-block;
            color: #fff;
            text-align: left;
            width: 580px;
            position: relative;
            top: -61px;
            h1 {
                padding-bottom: 30px;
                font-weight: 400;
                font-size: 46px;
            }
            p {
                line-height: 28px;
                font-size: 28px;
                padding-bottom: 14px;
            }
            .home-notice-left-btns {
                margin-top: 38px;
                padding-left: 7px;
                div {
                    display: inline-block;
                    padding: 9px 15px;
                    background-image: linear-gradient(
                        90deg,
                        #ff6f21 0%,
                        #ffa574 100%
                    );
                    border-radius: 20px;
                    width: 110px;
                    text-align: center;
                    margin-right: 20px;
                    cursor: pointer;
                    &:hover {
                        background-image: linear-gradient(
                            90deg,
                            #f7912e 0%,
                            #ffa574 100%
                        );
                    }
                }
            }
        }
        .home-notice-right {
            display: inline-block;
            width: 700px;
            text-align: left;
            position: relative;
            top: 175px;
        }
        .home-notice-banner-wrap {
            cursor: pointer;
            display: inline-block;
            .home-notice-banner {
                // height: 800px;
                background-repeat: no-repeat;
                background-position: center;
                image-rendering: -moz-crisp-edges; /* Firefox */
                image-rendering: -o-crisp-edges; /* Opera */
                image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                image-rendering: crisp-edges;
                -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
            }
        }
    }
    .home-wrap {
        height: 1064px;

        .home-title {
            font-size: 40px;
            color: #333333;
            line-height: 56px;
            padding: 100px 0 10px;
        }
        .home-subtitle {
            font-size: 28px;
            color: #666666;
            line-height: 40px;
            padding-bottom: 60px;
        }
    }
    .home-product {
        width: 1200px;
        display: inline-block;
        .home-product-card {
            padding-top: 12px;
            width: 380px;
            height: 398px;
            background: #ffffff;
            box-shadow: 0 7px 20px 0 rgba(216, 216, 216, 0.7);
            text-align: center;
            margin-right: 30px;
            margin-bottom: 30px;
            vertical-align: top;
            h2 {
                color: #333333;
                line-height: 24px;
                padding: 17px 0 20px;
            }
            p {
                color: #9e9e9e;
                line-height: 32px;
                width: 300px;
                display: inline-block;
                padding-bottom: 60px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    .home-digitizing-bg {
        background: url(".~@imgs/home/digitizingbg.png") no-repeat center;
        background-color: #edeeef;
    }
    .home-digitizing {
        width: 1416px;
        height: 500px;
        display: inline-block;
        .home-digitizing-ul {
            width: 250px;
            display: inline-block;
            vertical-align: top;
            .home-digitizing-li {
                display: block;
                line-height: 125px;
                font-size: 28px;
                color: #fff;
                background-color: #3e3e3e;
                cursor: default;
                transition: all 0.4s ease;
            }
            .home-digitizing-li.active {
                background-color: #ff743c;
            }
        }
        .home-digitizing-content {
            display: inline-block;
            width: 1166px;
            height: 500px;
            background: url(".~@imgs/home/digitizing.png") no-repeat center;
            text-align: left;
            padding-top: 100px;
            padding-left: 50px;
            p {
                line-height: 40px;
                font-size: 24px;
                color: #666666;
            }
        }
    }

    .home-industry-bg {
        background: url(".~@imgs/home/industryNews/bg.png") no-repeat center;
        color: #fff;
        background-color: #0e234b;
    }
    .home-industry {
        white-space: nowrap;
        .home-industry-li {
            &:nth-child(-n + 2) {
                margin-right: 30px;
            }
            .home-industry-img {
                display: inline-block;
                overflow: hidden;
            }
            .home-industry-time {
                padding-top: 30px;
                color: #b4b6bd;
                text-align: left;
            }
            .home-industry-text {
                cursor: pointer;
                .home-industry-title {
                    text-align: left;
                    color: #b4b6bd;
                    line-height: 60px;
                    font-weight: normal;
                    width: 440px;
                    &:hover {
                        text-decoration: underline;
                        color: #ffffff;
                    }
                }
                /* .home-industry-intro {
                    text-align: left;
                    color: #b4b6bd;
                    font-size: 18px;
                } */
                /* &:hover,
                    &:visited {
                        .home-industry-title {
                            color: #ffffff;
                        }
                        .home-industry-intro {
                            color: #ffffff;
                            text-decoration: underline;
                        }
                    } */
            }
        }
    }
</style>

<style>
    .home-notice-banner-wrap .el-carousel__button {
        width: 60px;
        height: 5px;
        margin-bottom: 20px;
    }
    .home-notice-banner-wrap .el-carousel__arrow {
        height: 80px;
        width: 80px;
        margin-left: 40px;
        margin-right: 40px;
    }
</style>